.slide
  text-align: center
  padding: $globalMargin
  z-index: 5
  opacity: 0
  pointer-events: none
  overflow-y: auto
  overflow-x: hidden

  @media (min-width: $screen-lg)
    padding: $col * 0.75
    text-align: left
    mix-blend-mode: difference

  .container
    max-width: $col * 4
    margin: $col * 0.5 auto 0 auto

    @media (min-width: $screen-lg)
      margin: $col * 2 0 0 0

      @media (max-height: 880px)
        margin: $col 0 0 0

  .text-h2
    position: relative
    display: inline-block
    margin-bottom: $globalMargin * 0.25

    @media (min-width: $screen-lg)
      margin-bottom: $globalMargin * 0.5

    small
      +fontsize(16)
      vertical-align: top
      opacity: 0.4

      @media (min-width: $screen-lg)
        +fontsize(24)
        position: absolute
        top: 0
        right: 102%

  .no-overflow
    overflow: hidden
    padding: 0 $col
    margin: 0 $col * -1

    .content
      will-change: transform
      transition: transform $trDelay $trBezier
      transform: translateY(120%)

      &--delayed
        transition-delay: $trDelay * 0.1

  .cta, .input
    overflow: hidden
    text-align: right
    cursor: pointer
    padding: 0 $globalMargin * 0.5
    margin: 0 $globalMargin * -0.5
    user-select: none

    will-change: opacity, transform
    transition: opacity $trDelay $trBezier, transform $trDelay $trBezier
    opacity: 0
    pointer-events: none
    transform: translateX(10px)

    .main, .hovered
      will-change: transform
      transition: transform $trDelay $trBezier
      padding: 4px 0

    .hovered
      transition-delay: $trDelay * 0.1
      position: absolute
      top: 120%
      right: $globalMargin * 0.5

    &:hover
      .main, .hovered
        transform: translateY(-120%)

  .cta
    position: fixed
    bottom: $globalMargin
    right: $globalMargin

    @media (min-width: $screen-lg)
      bottom: $col
      right: $col * 0.75

  .input
    position: relative

  &.active
    opacity: 1
    pointer-events: auto

  &.entering
    .no-overflow
      .content
        transform: none

    .input
      opacity: 1
      transform: none
      pointer-events: auto

    .cta
      &.active
        opacity: 1
        transform: none
        pointer-events: auto

  &.leaving
    pointer-events: none

    .no-overflow
      .content
        transform: translateY(-120%)

    .cta, .input
      pointer-events: auto



