$trDelay: .75s
$trBezier: cubic-bezier(.54,.1,0,.99)

$black: #111111
$white: #fefefe

$purple: #511198
$yellow: #ffc107
$cyan: #4aaeff
$sky: #100917

$globalMargin: 30px
$col: 120px

$screen-sm: 640px
$screen-md: 768px
$screen-lg: 1024px
$screen-xl: 1280px
$screen-2xl: 1440px

$geomanist: "Geomanist", "Source Sans Pro", "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"

$leitura: "Leitura", "Source Sans Pro", "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial", sans-serif
