.wishesContainer
  pointer-events: none
  z-index: 6
  text-align: center

  @media (min-width: $screen-lg)
    mix-blend-mode: difference
    text-align: left

  .wish
    max-width: $col * 4
    width: 100%
    pointer-events: none
    position: absolute
    top: $col * 0.75
    left: 50%
    transform: translateX(-50%)
    padding: 0 $globalMargin
    opacity: 0

    @media (min-width: $screen-lg)
      max-width: $col * 3
      top: $col * 4
      right: $col * 0.75
      left: auto
      transform: none
      padding: 0

      @media (max-height: 880px)
        top: $col * 3

  .message
    font-style: italic

    &::before
      content: "“"

    &::after
      content: "”"

  .author
    padding-top: $globalMargin * 0.5

    &::before
      content: "—"

  .no-overflow
    overflow: hidden

    .content
      will-change: transform
      transition: transform $trDelay $trBezier
      transform: translateY(120%)

  .wish
    &.active
      opacity: 1

    &.entering
      pointer-events: auto

      .content
        transform: none

    &.leaving
      pointer-events: none
      transition-delay: none

      .content
        transform: translateY(-120%)

  &.send
    @media (max-width: $screen-lg - 1)
      .wish
        pointer-events: none

        .content
          transform: translateY(120%)
