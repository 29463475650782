.slide--loading
  display: flex
  align-items: center
  justify-content: center
  user-select: none

  .centered
    will-change: opacity
    transition: opacity $trDelay $trBezier

    .switch
      display: block
      text-align: center
      margin-top: $globalMargin
      will-change: opacity
      transition: opacity $trDelay $trBezier
      opacity: 0

  .intro
    position: relative
    will-change: transform
    transition: transform $trDelay $trBezier
    overflow: hidden
    max-height: 44px

    @media (min-width: $screen-lg)
      max-height: 96px

  .text
    will-change: transform
    transition: transform $trDelay $trBezier
    transform: translateY(120%)
    overflow: hidden

    &--loaded
      position: absolute
      top: 120%
      left: 50%
      transform: translateX(-50%)
      width: 100%
      text-align: center
      cursor: pointer

  .carousel
    display: inline-block
    position: relative

    .main, .sub
      will-change: transform
      transition: transform $trDelay $trBezier
      display: inline-block

    .main
      animation: load $trDelay * 2 $trBezier $trDelay * 2 infinite alternate both

    .sub
      position: absolute
      top: 120%
      left: 0
      animation: load $trDelay * 2 $trBezier $trDelay * 2 infinite alternate both

  .credit
    +fontsize(14)
    position: absolute
    text-align: center
    bottom: $globalMargin
    right: 0
    line-height: 1.2
    width: 100%

    span, a
      will-change: opacity
      transition: opacity $trDelay $trBezier
      opacity: 0

    a:hover
      opacity: 1 !important

    @media (min-width: $screen-lg)
      width: auto
      bottom: $col
      right: $col * 0.75
      text-align: right

  &.entering
    .text--loading
      transform: none
      transition-duration: $trDelay * 2

    .switch
      opacity: 1

    .credit
      span, a
        opacity: 0.6

  &.loaded
    .text--loading
      transform: translateY(-120%)

    .text--loaded
      transform: translateX(-50%) translateY(-120%)

    .switch
      opacity: 1

    .credit
      span, a
        opacity: 0.6

    .intro:hover
      transform: scale(1.1)

      & + .switch
        opacity: 0.2

      & ~ .credit
        span, a
          opacity: 0.2

  &.leaving
    .centered
      opacity: 0

    .text--loading
      transform: translateY(-120%)

    .text--loaded
      transform: translateX(-50%) translateY(-120%)

@keyframes load
  0%
    transform: translateY(0%)
  100%
    transform: translateY(-120%)

