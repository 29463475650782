.slide--form
  form.wish
    display: block
    position: relative

  input, textarea
    display: block
    width: 100%
    height: auto
    resize: none
    background: rgba($sky, 0.6)
    padding: $globalMargin * 0.25

    &#submit
      display: none

  label
    display: block
    margin: $globalMargin * 0.25 0

  textarea
    height: $globalMargin * 4

    @media (min-width: $screen-lg)
      height: $globalMargin * 3

  .input
    display: inline-block
    margin-top: $globalMargin * 0.25
    overflow: hidden

  .inputWrapper
    will-change: opacity, transform !important
    transition: opacity $trDelay $trBezier, transform $trDelay $trBezier !important

  .after
    position: absolute
    top: $globalMargin * 0.25
    left: 0
    width: 100%
    margin: 0
    padding: 0

    &__content
      will-change: transform
      transition: transform $trDelay $trBezier $trDelay * 0.5
      transform: translateY(120%)

  form.wish
    &.submitting
      pointer-events: none !important

      .inputWrapper
        opacity: 0.6

      .input
        pointer-events: none !important

    &.submitted
      .inputWrapper
        opacity: 0.6

      .inputWrapper, .input
        pointer-events: none !important

      .content
        transition-delay: none !important
        transform: translateY(120%) !important

      .after__content
        transform: none

  .mobileSwitch
    margin-top: $globalMargin
    margin-bottom: $globalMargin * -0.5

    @media (min-width: $screen-lg)
      display: none

    .floating
      position: fixed
      bottom: $globalMargin * 0.5
      left: 0
      width: 100%

      .no-overflow
        position: absolute
        bottom: 0
        left: 0
        width: 100%
        margin: 0
        padding: 0

    .no-overflow
      pointer-events: none

    .seeWishes, .sendWish
      text-decoration: underline
      pointer-events: auto
      cursor: pointer
      user-select: none

  &.entering
    @media (max-width: $screen-lg - 1)
      &.send
        .content
          transition-delay: $trDelay * 0.5

        .seeWishes
          transform: none
          transition-delay: $trDelay * 0.5

        .sendWish
          transform: translateY(120%)
          pointer-events: none

      &.see
        overflow-y: hidden !important

        .content, .after__content
          transform: translateY(120%) !important
          transition-delay: 0s !important

        .seeWishes
          transform: translateY(120%) !important
          pointer-events: none

        .sendWish
          transform: none !important
          transition-delay: $trDelay * 0.5
