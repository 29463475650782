.partials
  pointer-events: none
  padding: $globalMargin
  z-index: 10

  @media (min-width: $screen-lg)
    mix-blend-mode: difference
    padding: $col * 0.5 $col * 0.75

  header
    display: flex
    justify-content: space-between

  .logo
    height: 30px
    pointer-events: auto
    will-change: opacity
    transition: opacity $trDelay $trBezier
    opacity: 1

    &:hover
      opacity: 0.4

    svg
      height: inherit
      width: auto
      max-width: 48px


